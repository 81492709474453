<template>
  <div class="documents">
    <Loader v-if="isLoading" />

    <div class="container" v-if="!isLoading">
      <div class="h4">Документы</div>
      <div class="documents-list">
        <button
          @click="openModal(i)"
          class="documents-item"
          v-for="(document, i) in documentsList"
          :key="i">
          <div class="documents-item__preview">
            <div class="documents-item__preview-background">
              <img class="documents-item__preview-background-image" v-lazyload="getPreviewUrl(document.file, false)" />
            </div>
            <img class="documents-item__preview-image" v-lazyload="getPreviewUrl(document.file, true)" />
          </div>
          <div class="documents-item__overlay">
            <img src="@/assets/img/double-arrow.svg" />
          </div>
        </button>
      </div>
    </div>
    <transition name="fade">
      <MediaModal
        :video-modal="false"
        :is-first-index="isFirstIndex"
        :is-last-index="isLastIndex"
        :current-image="currentImage"
        @slideNext="slideNext"
        @slidePrev="slidePrev"
        @close="closeModal"
        v-if="isModalOpen" />
    </transition>
  </div>
</template>

<script>
import '@/assets/sass/documents.sass';
import { getPreviewUrl } from '@/helpers';
import { mapState } from 'vuex';
import config from '@/config';
import MediaModal from '@/components/Archive/MediaModal.vue';
import $ from 'jquery';
import Loader from '@/components/Loader.vue';

export default {
  name: 'Documents',
  components: { Loader, MediaModal },
  data() {
    return {
      isLoading: true,
      isModalOpen: false,
      currentImageIndex: 0,
    };
  },
  computed: {
    ...mapState({
      documentsList: (state) => state.documents.documentsList,
    }),
    API_URL() {
      return config.API_URL;
    },
    currentImage() {
      if (!this.documentsList.length) return { file: {} };
      return this.documentsList[this.currentImageIndex];
    },
    isFirstIndex() {
      return this.currentImageIndex === 0;
    },
    isLastIndex() {
      if (!this.documentsList.length) return false;
      return this.currentImageIndex === this.documentsList.length - 1;
    },
  },
  methods: {
    loadData() {
      this.$store.dispatch('getDocumentsList').then(() => {
        this.isLoading = false;
      });
    },
    slidePrev() {
      if (this.isFirstIndex) return;
      this.currentImageIndex -= 1;
    },
    slideNext() {
      if (this.isLastIndex) return;
      this.currentImageIndex += 1;
    },
    openModal(i) {
      this.currentImageIndex = i;
      this.isModalOpen = true;
      $('body').css('overflow', 'hidden');
    },
    closeModal() {
      this.isModalOpen = false;
      $('body').css('overflow', 'auto');
    },
    getPreviewUrl(data) {
      return getPreviewUrl(data);
    },
  },
  mounted() {
    this.isLoading = true;
    this.loadData();
    this.$emit('setLastCrumb', 'Документы');
  },
};
</script>
